import { Route, Routes } from 'react-router-dom';
import NormalHeader from '@tkl-apps/ticketlink/src/components/HTMLHeader/NormalHeader';
import useConditionalCSS from '@tkl-apps/ticketlink/src/hooks/header/useConditionalCSS';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { isPc } from '@tkl-packages/sdk/src/validations';
import { Advertisement } from '../../pages/pc';

const AdvertisementRoute = () => {
  useConditionalCSS({
    href: `${process.env.CSS_URL}/contents.css`,
    isActive: isPc()
  });

  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Advertisement />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default AdvertisementRoute;
