import { FC, PropsWithChildren } from 'react';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

const NormalHeader: FC<PropsWithChildren<{ disableGoogleAnalytics?: boolean }>> = ({
  children,
  disableGoogleAnalytics
}) => {
  return (
    <>
      <OgTag />
      {!disableGoogleAnalytics && <GoogleAnalytics />}
      {children}
    </>
  );
};

export default NormalHeader;
