import { ChangeEvent, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { NotFoundMobile, RenewalTicketMobile4Email } from '@tkl-apps/ticketlink/src/pages/mobile';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { PageLoading } from '@tkl-packages/components/src/ticketlink/Loading';
import { isPc } from '@tkl-packages/sdk/src/validations';
import NormalHeader from '../components/HTMLHeader/NormalHeader';
import { NotFound } from '../pages/pc';
import ESportsRoute from './paths/Global/ESports';
import ExhibitionRoute from './paths/Global/Exhibition';
import HomeRoute from './paths/Global/Home';
import PerformanceRoute from './paths/Global/Performance';
import PlanningEventRoute from './paths/Global/PlanningEvent';
import MobileRoute from './paths/Mobile';
import ProductRoute from './paths/Product';

export const useTranslationFromUrl = () => {
  const { t } = useTranslation();
  const { locale: lng } = useParams<{ locale: string }>();

  return (text: string) => t(text, { lng });
};

export const useLanguageChange = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return async (e: ChangeEvent<HTMLSelectElement>) => {
    await i18n.changeLanguage(e.target.value);

    if (e.target.value === 'ko') navigate(`/home`);
    navigate(`/global/${e.target.value}/home`);
  };
};

const GlobalOutlet = () => {
  const { locale } = useParams<{ locale: string }>();

  if (locale === 'ko') {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

const GlobalRoute = () => {
  useEffect(() => {
    if (isPc()) {
      document.querySelector('link[href*="/common.css"]')?.setAttribute('rel', 'stylesheet');
      document.querySelector('link[href*="/ticketlink_renewal.css"]')?.setAttribute('rel', 'stylesheet');
    } else {
      document.querySelector('link[href*="/w.css"]')?.setAttribute('rel', 'stylesheet');
      document.querySelector('link[href*="/m_ticketlink.css"]')?.setAttribute('rel', 'stylesheet');
    }

    return () => {
      if (isPc()) {
        document.querySelector('link[href*="/w.css"]')?.remove();
        document.querySelector('link[href*="/m_ticketlink.css"]')?.remove();
      } else {
        document.querySelector('link[href*="/common.css"]')?.remove();
        document.querySelector('link[href*="/ticketlink_renewal.css"]')?.remove();
      }
    };
  }, []);

  return (
    <Suspense fallback={<PageLoading useBackground={false} />}>
      <Routes>
        <Route path="*" element={<GlobalOutlet />}>
          <Route index element={<Navigate to="home" />} />
          <Route path="home" element={<HomeRoute />} />
          <Route path="esports/*" element={<ESportsRoute />} />
          <Route path="product/*" element={<ProductRoute />} />
          <Route path="performance/*" element={<PerformanceRoute />} />
          <Route path="exhibition/*" element={<ExhibitionRoute />} />
          <Route path="planning-event/*" element={<PlanningEventRoute />} />
          <Route
            path="mobile/*"
            element={<MobileRoute ticket={<Channel mobile={<RenewalTicketMobile4Email />} />} />}
          />
          <Route
            path="404"
            element={
              <NormalHeader>
                <Channel pc={<NotFound />} mobile={<NotFoundMobile />} />
              </NormalHeader>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to=".." />} />
      </Routes>
    </Suspense>
  );
};

export default GlobalRoute;
