import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

type PolicyHeaderProps = {
  channel?: 'pc' | 'mo';
};

const PolicyHeader = ({ channel, children }: PropsWithChildren<PolicyHeaderProps>) => {
  const location = useLocation();

  return (
    <>
      <OgTag />
      {channel ? (
        <>
          {channel === 'pc' && (
            <Helmet key={location.key}>
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/common.css`} />
              <link
                rel="stylesheet"
                type="text/css"
                href={`${process.env.CSS_PC_URL_RENEWAL}/ticketlink_renewal.css`}
              />
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/policy.css`} />
            </Helmet>
          )}
          {channel === 'mo' && (
            <Helmet key={location.key}>
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_MO_URL}/w.css`} />
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_MO_URL_RENEWAL}/m_ticketlink.css`} />
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/policy.css`} />
            </Helmet>
          )}
        </>
      ) : (
        <Channel
          pc={
            <Helmet key={location.key}>
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/common.css`} />
              <link
                rel="stylesheet"
                type="text/css"
                href={`${process.env.CSS_PC_URL_RENEWAL}/ticketlink_renewal.css`}
              />
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/policy.css`} />
            </Helmet>
          }
          mobile={
            <Helmet key={location.key}>
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_MO_URL}/w.css`} />
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_MO_URL_RENEWAL}/m_ticketlink.css`} />
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/policy.css`} />
            </Helmet>
          }
        />
      )}
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default PolicyHeader;
