import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { isFacility } from '../validations';
import { COOKIE_KEY } from './constants';

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

let baseURL = setBaseUrl();

export function setBaseUrl() {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const isDevServer = process.env.DEV_SERVER === 'true';
  let url = 'https://mapi.ticketlink.co.kr/mapi/';

  if (isDrTour()) {
    if (isDevDrTour()) {
      return 'https://new-mapi.ticketlink.co.kr/mapi/';
    }
    return url;
  }

  if ((isDevelopment && isDevServer) || typeof window === 'undefined') {
    url = '/mapi/';
  } else {
    const { hostname } = location;
    const phase = hostname
      .replace('.ticketlink.co.kr', '')
      .replace('-m', '')
      .replace('-appweb', '')
      .replace('-facility', '');
    const targets = ['local', 'new', 'alpha', 'alpha2', 'beta', 'beta2', 'stage'];
    const alphaDB = ['local', 'new', 'alpha', 'alpha2'];

    if (targets.some((target) => phase.indexOf(target) > -1)) {
      let mapiHost = phase;
      if (phase === 'local') {
        //FIXME: local인경우 new를 바라봄
        mapiHost = 'new';
      }

      if (alphaDB.includes(phase)) {
        if (localStorage) {
          const isLocalMode = localStorage.getItem('localMode') === 'true';
          if (isLocalMode) {
            mapiHost = 'local';
          }
        }
      }

      if (localStorage) {
        const mapiPhase = localStorage.getItem('mapiPhase');
        if (mapiPhase) {
          mapiHost = mapiPhase;
        }
      }

      url = `https://${mapiHost}-mapi.ticketlink.co.kr/mapi/`;
    }
  }

  return url;
}

function isDrTour() {
  if (typeof window === 'undefined') {
    return false;
  }
  const { hostname } = location;
  return hostname.includes('tour');
}

function isDevDrTour() {
  if (typeof window === 'undefined') {
    return false;
  }
  const { hostname } = location;
  return hostname.includes('dev-');
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000
});

axiosInstance.interceptors.request.use((request) => {
  const token = Cookies.get(isFacility() ? COOKIE_KEY.API_FACILITY_TOKEN : COOKIE_KEY.API_TOKEN);
  if (token && request?.headers) {
    request.headers['x-access-token'] = token;
  }
  return request;
});

const requests = {
  get: <T, D>(url: string, params?: D, timeout?: number) =>
    axiosInstance.get<T>(url, { params, timeout }).then(responseBody),
  post: <T, D>(url: string, body: D) => axiosInstance.post<T>(url, body).then(responseBody),
  put: <T, D>(url: string, body: D) => axiosInstance.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axiosInstance.delete<T>(url).then(responseBody)
};

export { requests };
