import { FC, PropsWithChildren } from 'react';
import useConditionalCSS from '@tkl-apps/ticketlink/src/hooks/header/useConditionalCSS';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';
import { isPc } from '@tkl-packages/sdk/src/validations';

const SportsOffseasonHeader: FC<PropsWithChildren> = ({ children }) => {
  useConditionalCSS({
    href: `${process.env.CSS_URL}/popup.css`,
    isActive: isPc()
  });
  useConditionalCSS({
    href: `${process.env.CSS_URL}/sports.css`,
    isActive: isPc()
  });

  return (
    <>
      <OgTag />
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default SportsOffseasonHeader;
