import { Navigate, Route, Routes } from 'react-router-dom';
import NormalHeader from '@tkl-apps/ticketlink/src/components/HTMLHeader/NormalHeader';
import PopupHeader from '@tkl-apps/ticketlink/src/components/HTMLHeader/PopupHeader';
import useConditionalCSS from '@tkl-apps/ticketlink/src/hooks/header/useConditionalCSS';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { isPc } from '@tkl-packages/sdk/src/validations';
import {
  GuidePopupSmartTicketMobile,
  GuideWaitingReservationMobile,
  HelpMobile,
  NotFoundMobile
} from '../../pages/mobile';
import {
  Faq,
  FaqDetail,
  GuideCancel,
  GuideCharge,
  GuideDiscountCard,
  GuideDiscountMethod,
  GuideLegal,
  GuideMemberInfo,
  GuidePayment,
  GuidePopupSmartTicket,
  GuideReservation,
  GuideReservationChannel,
  GuideTicket,
  GuideWaitingReservation,
  NotFound,
  Partner
} from '../../pages/pc';

const HelpRoute = () => {
  useConditionalCSS({
    href: `${process.env.CSS_URL}/contents.css`,
    isActive: isPc()
  });

  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel mobile={<HelpMobile />} />
          </NormalHeader>
        }
      />
      <Route
        path="partner"
        element={
          <NormalHeader>
            <Channel pc={<Partner />} />
          </NormalHeader>
        }
      />
      <Route path="guide">
        <Route
          index
          element={
            <NormalHeader>
              <Channel pc={<NotFound />} mobile={<NotFoundMobile />} />
            </NormalHeader>
          }
        />
        <Route
          path="cancel"
          element={
            <NormalHeader>
              <Channel pc={<GuideCancel />} />
            </NormalHeader>
          }
        />
        <Route
          path="charge"
          element={
            <NormalHeader>
              <Channel pc={<GuideCharge />} />
            </NormalHeader>
          }
        />
        <Route
          path="channel"
          element={
            <NormalHeader>
              <Channel pc={<GuideReservationChannel />} />
            </NormalHeader>
          }
        />
        <Route
          path="discountCard/baseball"
          element={
            <NormalHeader>
              <Channel pc={<GuideDiscountCard />} />
            </NormalHeader>
          }
        />
        <Route
          path="discountMethod"
          element={
            <NormalHeader>
              <Channel pc={<GuideDiscountMethod />} />
            </NormalHeader>
          }
        />
        <Route
          path="legalGuide"
          element={
            <NormalHeader>
              <Channel pc={<GuideLegal />} />
            </NormalHeader>
          }
        />
        <Route
          path="memberInfo"
          element={
            <NormalHeader>
              <Channel pc={<GuideMemberInfo />} />
            </NormalHeader>
          }
        />
        <Route path="reservation" element={<Navigate to="/help/guide/reservation/baseball" replace />} />
        <Route
          path="reservation/:param"
          element={
            <NormalHeader>
              <Channel pc={<GuideReservation />} />
            </NormalHeader>
          }
        />
        <Route
          path="ticket"
          element={
            <NormalHeader>
              <Channel pc={<GuideTicket />} />
            </NormalHeader>
          }
        />
        <Route
          path="payment"
          element={
            <NormalHeader>
              <Channel pc={<GuidePayment />} />
            </NormalHeader>
          }
        />
        <Route
          path="waitingGuide"
          element={
            <NormalHeader>
              <Channel pc={<GuideWaitingReservation />} mobile={<GuideWaitingReservationMobile />} />
            </NormalHeader>
          }
        />
        <Route path="popup">
          <Route
            path="smart-ticket"
            element={
              <PopupHeader>
                <Channel pc={<GuidePopupSmartTicket />} mobile={<GuidePopupSmartTicketMobile />} />
              </PopupHeader>
            }
          />
        </Route>
      </Route>
      <Route path="faq/*">
        <Route
          index
          element={
            <NormalHeader>
              <Channel pc={<Faq />} />
            </NormalHeader>
          }
        />
        <Route
          path=":faqId"
          element={
            <NormalHeader>
              <Channel pc={<FaqDetail />} />
            </NormalHeader>
          }
        />
        <Route
          path="*"
          element={
            <NormalHeader>
              <Navigate to=".." />
            </NormalHeader>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default HelpRoute;
