import { Navigate, Route, Routes } from 'react-router-dom';
import NormalHeader from '@tkl-apps/ticketlink/src/components/HTMLHeader/NormalHeader';
import WithLoginOutlet from '@tkl-apps/ticketlink/src/components/User/WithLoginOutlet';
import useConditionalCSS from '@tkl-apps/ticketlink/src/hooks/header/useConditionalCSS';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { isPc } from '@tkl-packages/sdk/src/validations';
import {
  AdvanceTicketMobile,
  CertificationIdentityMobile,
  DeviceAuthMobile,
  DeviceRegisterMobile,
  FaceEntranceMobile,
  FaceRegisterMobile,
  FaceTermsMobile,
  MyMobile,
  NotFoundMobile,
  WithdrawalMobile
} from '../../pages/mobile';
import { NotFound } from '../../pages/pc';

const MyRoute = () => {
  useConditionalCSS({
    href: `${process.env.CSS_URL}/contents.css`,
    isActive: isPc()
  });

  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel mobile={<MyMobile />} />
          </NormalHeader>
        }
      />
      <Route path="*" element={<WithLoginOutlet />}>
        {/*<Route path="document/*">*/}
        {/*  <Route*/}
        {/*    path="deposit"*/}
        {/*    element={*/}
        {/*      <NormalHeader enableContentsCSS>*/}
        {/*        <Channel pc={<MyDeposit />} />*/}
        {/*      </NormalHeader>*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Route*/}
        {/*    path="cash"*/}
        {/*    element={*/}
        {/*      <NormalHeader enableContentsCSS>*/}
        {/*        <Channel pc={<MyCash />} />*/}
        {/*      </NormalHeader>*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Route*/}
        {/*    path="creditCard"*/}
        {/*    element={*/}
        {/*      <NormalHeader enableContentsCSS>*/}
        {/*        <Channel pc={<MyCreditCard />} />*/}
        {/*      </NormalHeader>*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Route*/}
        {/*    path="*"*/}
        {/*    element={*/}
        {/*      <NormalHeader enableContentsCSS>*/}
        {/*        <Channel pc={<NotFound />} />*/}
        {/*      </NormalHeader>*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Route>*/}
        <Route path="certification/*">
          <Route
            index
            element={
              <NormalHeader>
                <Channel pc={<NotFound />} mobile={<NotFoundMobile />} />
              </NormalHeader>
            }
          />
          <Route
            path="identity"
            element={
              <NormalHeader>
                <Channel mobile={<CertificationIdentityMobile />} />
              </NormalHeader>
            }
          />
        </Route>

        <Route path="device/*">
          <Route
            index
            element={
              <NormalHeader>
                <Channel pc={<NotFound />} mobile={<NotFoundMobile />} />
              </NormalHeader>
            }
          />
          <Route
            path="auth"
            element={
              <NormalHeader>
                <Channel mobile={<DeviceAuthMobile />} />
              </NormalHeader>
            }
          />
          <Route
            path="register"
            element={
              <NormalHeader>
                <Channel mobile={<DeviceRegisterMobile />} />
              </NormalHeader>
            }
          />
        </Route>

        <Route path="face/*">
          <Route
            index
            element={
              <NormalHeader>
                <Channel pc={<NotFound />} mobile={<NotFoundMobile />} />
              </NormalHeader>
            }
          />
          <Route
            path="entrance"
            element={
              <NormalHeader>
                <Channel mobile={<FaceEntranceMobile />} />
              </NormalHeader>
            }
          />
          <Route
            path="register"
            element={
              <NormalHeader>
                <Channel mobile={<FaceRegisterMobile />} />
              </NormalHeader>
            }
          />
          <Route
            path="terms"
            element={
              <NormalHeader>
                <Channel mobile={<FaceTermsMobile />} />
              </NormalHeader>
            }
          />
        </Route>

        <Route
          path="advanceTicket"
          element={
            <NormalHeader>
              <Channel mobile={<AdvanceTicketMobile />} />
            </NormalHeader>
          }
        />

        <Route
          path="withdrawal"
          element={
            <NormalHeader>
              <Channel mobile={<WithdrawalMobile />} />
            </NormalHeader>
          }
        />

        <Route path="*" element={<Navigate to=".." />} />
      </Route>
    </Routes>
  );
};

export default MyRoute;
