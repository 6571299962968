import { useEffect } from 'react';

/**
 * CSS 파일을 조건부로 제어하기 위한 커스텀 훅 (사용대상: index.html에서 preload 된 CSS)
 *
 * @param href - CSS 파일 경로
 * @param isActive - CSS 활성화 여부
 *
 * @desc CSS 활성화 여부 값에 따라 `<link>` 요소의 상태를 제어한다.
 * - `true`: `rel` 속성을 `"stylesheet"`로 하여 스타일 적용 활성화
 * - `false`: `<link>` 요소를 제거한 뒤 `rel="preload"`로 다시 추가해 CSS를 미리 로드하도록 만들되 적용하진 않도록 설정
 * @warn 이 훅은 `href`, `isActive` 값을 의존성 배열에 두고 있기 때문에 해당 값이 빈번하게 변경되지 않도록 주의한다. (route level 사용 권장)
 */
const useConditionalCSS = ({ href, isActive }: { href: string; isActive: boolean }) => {
  useEffect(() => {
    if (!isActive) return;

    const linkEl = document.querySelector(`link[href*="${href}"]`);

    // CSS 활성화
    linkEl?.setAttribute('rel', 'stylesheet');

    return () => {
      // CSS 비활성화
      if (linkEl?.getAttribute('rel') === 'stylesheet') {
        linkEl.remove();

        const newLinkEl = document.createElement('link');
        newLinkEl.rel = 'preload';
        newLinkEl.as = 'style';
        newLinkEl.href = href;
        document.head.appendChild(newLinkEl);
      }
    };
  }, [isActive, href]);
};

export default useConditionalCSS;
